<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <div>
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <b-tabs
      pills
    >
      <b-tab
        active
        title="Aliquote Iva"
      >
        <b-card>
          <ejs-toast
            id="toast_type"
            ref="toastRef"
            :position="{ X: 'Right' }"
          />
          <div class="col-lg-12 control-section mt-2 p-0">
            <div class="content-wrapper">
              <!-- <div>
                <ejs-dropdownlist
                  id="dropdownlist"
                  ref="dropdown"
                  :data-source="ddData"
                  width="220px"
                  :fields="fields"
                  :change="valueChange"
                  :value="ddValue"
                />
                <span
                  id="msg"
                  ref="msgelement"
                  v-html="loadTime"
                />
                <br>
              </div> -->
              <!-- {{ rows }} -->
              <ejs-grid
                id="overviewgridIva"
                ref="overviewgridIva"
                locale="it-IT"
                :data-source="rows"
                :selection-settings="selectionSettings"
                :edit-settings="editSettings"
                :filter-settings="filterOptions"
                :show-column-chooser="true"
                :allow-selection="true"
                :allow-sorting="true"
                :allow-filtering="true"
                :allow-resizing="true"
                :enable-virtualization="false"
                :allow-row-drag-and-drop="true"
                height="300"
                :pdf-header-query-cell-info="pdfHeaderQueryCellInfo"
                :allow-pdf-export="true"
                :allow-excel-export="true"
                row-height="38"
                :enable-hover="false"
                :enable-header-focus="true"
                :load="load"
                :toolbar="toolbar"
                :toolbar-click="toolbarClick"
                :action-begin="actionTableBegin"
                :action-complete="actionTableComplete"
                :allow-grouping="true"
                :allow-paging="true"
                :page-settings="pageSettings"
                :context-menu-items="contextMenuItems"
                :context-menu-click="contextMenuClick"
                :data-bound="dataBound"
                :command-click="commandColumm"
              >
                <e-columns>
                  <e-column
                    type="checkbox"
                    :allow-filtering="false"
                    :allow-sorting="false"
                    width="60"
                  />
                  <e-column
                    field="id"
                    :visible="false"
                    header-text="ID"
                    :is-primary-key="true"
                    width="130"
                  />
                  <e-column
                    field="aliquota"
                    header-text="aliquota"
                    width="150"
                    :filter="filter"
                  />
                  <e-column
                    field="descrizione"
                    header-text="Descrizione"
                    width="230"
                    :filter="filter"
                  />
                  <e-column
                    field="codice"
                    header-text="Codice"
                    width="200"
                    :filter="filter"
                    clip-mode="EllipsisWithTooltip"
                  />
                  <e-column
                    field="natura"
                    header-text="Natura"
                    width="150"
                    :filter="filter"
                    clip-mode="EllipsisWithTooltip"
                    :allow-editing="false"
                  />
                  <e-column
                    field="percentualeDetraibile"
                    header-text="% Detraibile"
                    width="100"
                    :filter="filter"
                  />
                  <e-column
                    field="zona"
                    header-text="Zona"
                    width="150"
                    :filter="filter"
                    :visible="false"
                  />
                  <e-column
                    field="note"
                    header-text="Noe"
                    width="100"
                    :filter="filter"
                    :visible="false"
                  />
                  <e-column
                    field="Azioni"
                    :lock-column="true"
                    header-text="Azioni"
                    width="240"
                    :template="cTemplate"
                    :allow-editing="false"
                    :commands="commands"
                    :allow-sorting="false"
                    :allow-filtering="false"
                  />
                </e-columns>
              </ejs-grid>
            </div>
          </div>
          <AliquoteIva
            ref="modal-aliquotaiva-event-handler"
            @update-aliquotaiva="updateAliquotaiva"
          />
        </b-card>
      </b-tab>
      <!-- <b-tab
        title="Profilo Fiscale"
      >
        <profilo-fiscale-tabs />
      </b-tab> -->
    </b-tabs>
  </div>
</template>
<script>
/* eslint-disable no-plusplus */
/* eslint-disable no-multi-str */
/* eslint-disable vue/no-side-effects-in-computed-properties */

import Vue from 'vue'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import {
  GridPlugin, Sort, Filter, Selection, PdfExport, ExcelExport, Toolbar, Edit, Page, ColumnChooser, RowDD, ContextMenu, CommandColumn, Resize, Scroll,
} from '@syncfusion/ej2-vue-grids'
// import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns'
import Ripple from 'vue-ripple-directive'
import { italian } from '@/@core/grid-translation/it-IT'
import moment from 'moment'
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { BCard, BButton, BRow, BCol, VBToggle, BBadge, BTabs, BTab } from 'bootstrap-vue'
import { getAliquoteIva, deleteAliquote } from '@/@core/api-service/dizionari/aliquotaIva'
import AliquoteIva from '@core/components/dizionari/AliquoteIva.vue'
// import ProfiloFiscaleTabs from './tabs/ProfiloFiscaleTabs.vue'

setCulture('it-IT');
//Vue.use(DropDownListPlugin)
L10n.load(italian)
/* L10n.load({
    'it-IT': {
        'grid': {
            'EmptyRecord': 'Nessun record',
            'GroupDropArea': "Trascina qui l'intestazione di una colonna per raggruppare la colonna",
            'UnGroup': 'Clicca qui per separare',
            'EmptyDataSourceError': 'DataSource non deve essere vuoto al caricamento iniziale perché le colonne di dataSource si trovano nella griglia di colonne AutoGenerate',
            'Item': 'Item',
            'Items': 'Items',
            'Add': 'Aggiungi',
            'Edit': 'Modifica',
            'Cancel': 'Annulla',
            'Delete': 'Elimina',
            'Print': 'Stampa',
            'Pdfexport': 'Esporta PDF',
            'Excelexport': 'Esporta Excel',
            'Wordexport': 'Esporta Word',
            'Csvexport': 'Esporta CSV',
            'Columnchooser': 'Colonne',
            'Save': 'Salva',
        },
        'pager':{
            'currentPageInfo': '{0} di {1} Pagine',
            'totalItemsInfo': '({0} Records)',
            'firstPageTooltip': 'Prima Pagina',
            'lastPageTooltip': 'Ultima Pagina',
            'nextPageTooltip': 'Pagina Successiva',
            'previousPageTooltip': 'Pagina Precedente',
            'nextPagerTooltip': 'Pagine Seguenti',
            'previousPagerTooltip': 'Pagine Precedenti'
        }
    }
});
 */
Vue.use(GridPlugin)
Vue.use(ToastPlugin)
export default Vue.extend({
  components: {
    BCard,
    BButton,
    AliquoteIva,
    BRow,
    BCol,
    BTabs,
    BTab,
    // ProfiloFiscaleTabs,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data: () => ({
          cTemplate: function () {
          return { template : Vue.component('columnTemplate',{
             template: `<div class="image">
                    <img :src="image" :alt="altImage"/>
                </div>`,
                data: function() {
                    return {
                        data: {}
                    }
                },
                computed: {
                    image: function() {
                        return './' + this.data.EmployeeID + '.png'
                    },
                    altImage: function() {
                        return this.data.EmployeeID
                    }
                }
          })
          }
          },
    warehouse: {},
    commands: [
        { type: 'Elimina', id: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        { type: 'Dettagli', id: 'Dettagli', target: '.e-column', buttonOption: { iconCss: 'e-icons e-folder-open', cssClass: 'e-flat' } }],
    contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
      'Copy', 'Save',
      'PdfExport', 'ExcelExport', 'CsvExport', 'FirstPage', 'PrevPage',
      'LastPage', 'NextPage',  'Group', 'Ungroup',
      {text: 'Dettagli', target: '.e-content', id: 'Dettagli', iconCss: 'e-icons e-folder-open' },
      {
        text: 'Elimina', tooltipText: 'Delete', iconCss: 'e-icons e-delete', id: 'DeleteCustom',
      },
    ],
    pageSettings: { pageSize: 25, pageSizes: [25,50,100,200], pageCount: 5 },
    showModalSettingPdfExport: false,
    editSettings: { allowFiltering: true, allowEditing: false, allowAdding: true, allowDeleting: true, mode: 'Normal' },
    toolbar: [
      {
        text: 'Aggiungi', tooltipText: 'Aggiungi', prefixIcon: 'e-plus', id: 'Aggiungi',
      },
      {
        text: 'Elimina', tooltipText: 'Elimina', prefixIcon: 'e-delete', id: 'Elimina',
      },
     'ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Print'],
    toolbarOptions: ['ExcelExport'],
    ddData: [{ value: 1000, text: '1,000 Rows and 11 Columns' }, { value: 10000, text: '10,000 Rows and 11 Columns' }],
    ddValue: 10000,
    stTime: null,
    loadTime: null,
    dReady: false,
    isDataChanged: true,
    fields: { text: 'text', value: 'value' },
    rows: [],
    shopTemplate() {
      return {
        template: Vue.component('shopTemplate', {
          components: {
            BBadge,
          },
          data() { return { data: {}, inactive: 'Inactive' } },
          template: `<div id="" class="">
                          <b-badge
                            v-for="(pv, index) in data.reparti"
                            :key="index"
                            class="mr-1"
                          >{{ pv.nome }}
                          </b-badge>
                        </div>`,
        }),
      }
    },
    filterOptions: {
      type: 'Menu',
    },
    filter: {
      type: 'CheckBox',
    },
    selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true },
  }),
  async mounted() {
    let dataSource = []
    const combo = await getAliquoteIva().then(res => {
      // console.log('getAliquoteIva', res)
      if (res.data.esito === 'OK') {
        // console.log('sono su ok getPlui')
        this.rows = [...res.data.aliquoteIva]
        // this.rows = [...res.data.plu]
      }
    }).catch(e => e)
  },
  computed: {
  },
  methods: {
    alertAndConfirm(id) {
      // console.log('sono su alert confirm id', id)
      this.$swal({
        title: 'Sei Sicuro?',
        text: 'Verranno rimossi permanentemente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(res => {
        if (res.value) {
          this.deleteAliquote(id)
        }
      })
    },
    deleteAliquote(id) {
      let recordToDelete = []
      if (id === undefined) recordToDelete = this.$refs.overviewgridIva.getSelectedRecords().map(el => ({ id: el.id }))
      else recordToDelete = id
      deleteAliquote(recordToDelete).then(res=> {
        if (res.data.esito === 'OK') {
          // console.log('res', res)
          recordToDelete.forEach(record => this.$refs.overviewgridIva.deleteRecord('id', record))
          // mixins
          // this.productDeletedCorrectlyAlert()
          this.$refs.toastRef.show({
            title: 'Operazione Completata!', content: 'Aliquota/e Eliminato/i Correttamente', cssClass: 'e-toast-success', icon: 'e-success toast-icons',
          })
        } else if(res.data.esito === 'KO') {
          // console.log('dentro ko')
          this.$refs.toastRef.show({
            title: 'Errore Cancellazione', content: res.data.messaggio, cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
          })
        }
      }).catch(e => e)
    },
    updateAliquotaiva(aliquote) {
      // console.log('emitter', listini)
      let typeOperation = ''
      aliquote.forEach(element => {
        const index = this.$refs.overviewgridIva.getRowIndexByPrimaryKey({ id: element.id})
        if(index >= 0) {
          this.$refs.overviewgridIva.updateRow(index, element)
          typeOperation = 'Aggiornato/i'
        } else {
          typeOperation = 'Aggiunto/i'
          this.$refs.overviewgridIva.addRecord(element, 0)
        }
      })
      // ad ogni update, controllo se il listino è predefinito, in caso mappo gli altri false.
      if(aliquote[0].predefinito === '1') {
        this.rows = this.rows.map(el => ({ ...el, predefinito: Number(el.id) === Number(aliquote[0].id) ? '1' : '0' }))
      }
      // console.log(this.listini, 'dopo mapping')
      this.$refs.toastRef.show({
        title: 'Operazione Completata!', content: `'Aliquota/i ${typeOperation} Correttamente'`, cssClass: 'e-toast-success', icon: 'e-success toast-icons',
      })
    },
    dataBound() {
      // console.log(this.$refs.overviewoverviewgridIvagridflowshop)
      this.$refs.overviewgridIva.autoFitColumns(['Azioni' ]);
    },
    commandColumm(args) {
      if(args.commandColumn.type === 'Dettagli') {
          this.$refs['modal-aliquotaiva-event-handler'].setTitle('Modifica Aliquota')
          this.$refs['modal-aliquotaiva-event-handler'].setAliquota(args.rowData)
          this.$refs['modal-aliquotaiva-event-handler'].openModal()
        }
      if (args.commandColumn.type === 'Elimina') {
        // console.log([{ id: args.rowData.id }])
        this.alertAndConfirm([{ id: args.rowData.id }])
      }
    },
    contextMenuClick(args) {
        console.log('contextMenuClick', args)
        if(args.item.id === 'Dettagli') {
          this.$refs['modal-aliquotaiva-event-handler'].setTitle('Modifica Aliquota')
          this.$refs['modal-aliquotaiva-event-handler'].setAliquota(args.rowInfo.rowData)
          this.$refs['modal-aliquotaiva-event-handler'].openModal()
        } else if (args.item.id === 'DeleteCustom') {
          this.alertAndConfirm([{ id: args.rowInfo.rowData.id }])
        }
    },
    closeModal() {
      this.showModalSettingPdfExport = false
    },
    actionTableBegin() {
      // console.log(action)
    },
    actionTableComplete() {
      // console.log(row)
    },
    pdfHeaderQueryCellInfo() {
      // args.cell.row.pdfGrid.repeatHeader = true;
    },
     toolbarClick(args) {
        // console.log(args)
        if (args.item.id === 'Dettagli') {
          alert("Custom Toolbar Click...");
        }
        if (args.item.id === 'Aggiungi') {
          this.$refs['modal-aliquotaiva-event-handler'].setTitle('Aggiungi Aliquota')
          this.$refs['modal-aliquotaiva-event-handler'].openModal()
        }
        if (args.item.id === 'Elimina' && this.$refs.overviewgridIva.getSelectedRecords().length > 0) {
          // console.log('delete')
          this.alertAndConfirm()
        }
        if (args.item.id === 'overviewgridIva_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
          const exportProperties = {
              //you can customize the name as per your requirement
              fileName: `AliquoteIva_${moment().format('DD-MM-YYYY')}.pdf`,
            }
          // this.$bvModal.show('setting-pdf-export-modal')
          this.$refs.overviewgridIva.pdfExport(exportProperties)
          // this.$refs.overviewgridflowshop.pdfExport(exportProperties)
      }
      if (args.item.id === 'overviewgridIva_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
          const exportProperties = {
              //you can customize the name as per your requirement
              fileName: `AliquoteIva_${moment().format('DD-MM-YYYY')}.xlsx`,
            }
          this.$refs.overviewgridIva.excelExport(exportProperties)
      }
      if (args.item.id === 'overviewgridIva_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
          const exportProperties = {
              //you can customize the name as per your requirement
              fileName: `AliquoteIva_${moment().format('DD-MM-YYYY')}.csv`,
            }
          this.$refs.overviewgridIva.csvExport(exportProperties)
      }
     },
    valueChange(args) {
      this.$refs.dropdown.$el.ej2_instances[0].closePopup()
      this.$refs.overviewgridIva.showSpinner()
      this.isDataChanged = true
      this.stTime = null
      const contentElement = this.$refs.overviewgridIva.ej2Instances.contentModule.content
      this.$refs.overviewgridIva.ej2Instances.pageSettings.currentPage = 1
      contentElement.scrollLeft = 0
      contentElement.scrollTop = 0
      console.log(this.$refs.overviewgridIva.ej2Instances.pageSettings.currentPage)
      this.stTime = performance.now()
      this.ddValue = args.value
      this.$refs.overviewgridIva.hideSpinner()
    },
    load() {
      const proxy = this
      this.$refs.overviewgridIva.$el.ej2_instances[0].on('data-ready', () => {
        proxy.dReady = true
      })
      this.$refs.overviewgridIva.$el.addEventListener('DOMSubtreeModified', () => {
        if (proxy.dReady && proxy.stTime && proxy.isDataChanged) {
          const e = performance.now() - proxy.stTime
          proxy.loadTime = `Load Time: <b>${e.toFixed(0)}</b><b>ms</b>`
          proxy.stTime = null
          proxy.dReady = false
          proxy.isDataChanged = false
          // proxy.$refs.msgelement.classList.remove('e-hide')
        }
      })
    },
    /* addFlowWarehouse(warehouse, title) {
      this.$refs['modal-aliquotaiva-event-handler'].setTitle(title)
      this.$refs['modal-aliquotaiva-event-handler'].openSidebar()

    }, */
  },
  provide: {
    grid: [Toolbar, PdfExport, ExcelExport, Filter, Selection, Sort, Edit, Page, ColumnChooser, RowDD, ContextMenu, CommandColumn, Resize, Scroll, Filter],
  },

})

</script>
<style scoped>
/* @import "../../../../../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap.css"; */
</style>
