/* eslint-disable */
export const italian = {
  'it-IT': {
      'grid': {
          'NoResult': 'Nessun risultato trovato',
          'DeleteOperationAlert': 'Nessuna riga selezionata',
          'EditOperationAlert': 'Nessuna riga selezionata',
          'ClearButton': 'Pulisci',
          'CancelButton': 'Annulla',
          'FilterButton': 'Filtra',
          'FilterFalse': 'Falso',
          'FilterTrue': 'Vero',
          'NumberFilter': 'Numero Filtri',
          'SearchColumns': 'Cerca Colonne',
          'ConfirmDelete': 'Sei sicuro di voler eliminare il record?',
          'Search': 'Cerca',
          'SelectAll': 'Seleziona Tutti',
          'All': 'Tutti',
          'Copy': 'Copia',
          'Blanks': 'Vuoto',
          'EditRecord': 'Modifica',
          'EmptyRecord': 'Nessun record',
          'DeleteRecord': 'Elimina',
          'Export': 'Esporta',
          'ChooseColumns': 'Seleziona Colonne',
          'StartsWith': 'Inizia Con',
          'EndsWith': 'Finisce Con',
          'Contains': 'Contiene',
          'Equal': 'Uguale',
          'NotEqual': 'Non uguale',
          'Group': 'Raggruppa per questa colonna',
          'Ungroup': 'Elimina gruppo per questa colonna',
          'autoFit': 'AutoFit Colonna',
          'autoFitAll': 'AutoFit tutte le colonne',
          'SortAscending': 'Ordinamento crescente',
          'TextFilter': 'Testo Filtri',
          'DateFilter': 'Filtro Date',
          'MatchCase': 'Match case',
          'SortDescending': 'Ordinamento decrescente',
          'EnterValue': 'Insersci Valore',
          'GroupDropArea': "Trascina qui l'intestazione di una colonna per raggruppare la colonna",
          'FilterMenu': 'Filtra',
          'UnGroup': 'Clicca qui per separare',
          'EmptyDataSourceError': 'DataSource non deve essere vuoto al caricamento iniziale perché le colonne di dataSource si trovano nella griglia di colonne AutoGenerate',
          'CustomFilter': 'Filtro Personalizzato',
          'CustomFilterPlaceHolder': 'Inserisci valore',
          'CustomFilterDatePlaceHolder': 'Inserisci Data',
          'ShowRowsWhere': 'Mostra Righe dove:',
          'Item': 'Item',
          'Items': 'Items',
          'Add': 'Aggiungi',
          'Edit': 'Modifica',
          'Cancel': 'Annulla',
          'Delete': 'Elimina',
          'Print': 'Stampa',
          'Pdfexport': 'Esporta PDF',
          'Excelexport': 'Esporta Excel',
          'Wordexport': 'Esporta Word',
          'Csvexport': 'Esporta CSV',
          'Columnchooser': 'Colonne',
          'Save': 'Salva',
          'pagerAllDropDown': 'Visualizza tutti',
          'ClearFilter': 'Pulisci Filtro',
          'MatchCase': 'Corrispondezza Esatta'
      },
      'pager':{
          'currentPageInfo': '{0} di {1} Pagine',
          'totalItemsInfo': '({0} Items)',
          'firstPageTooltip': 'Prima Pagina',
          'lastPageTooltip': 'Ultima Pagina',
          'nextPageTooltip': 'Pagina Successiva',
          'previousPageTooltip': 'Pagina Precedente',
          'nextPagerTooltip': 'Pagine Seguenti',
          'previousPagerTooltip': 'Pagine Precedenti',
          'pagerDropDown': 'Items per pagina'
      }
  }
}