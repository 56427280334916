var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-aliquotaiva-event-handler",attrs:{"id":"modal-aliquotaiva-event-handler","ok-only":"","ok-title":"Crea","centered":"","size":"md","title":_vm.titleEventHandler},on:{"ok":function($event){$event.preventDefault();return _vm.handleOk($event)},"hidden":(function () { return _vm.resetAliquota(); })},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('validation-observer',{ref:"aliquotaivaobserverform"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"8"}},[_c('validation-provider',{attrs:{"name":"aliquota","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Aliquota","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"aliquota","type":"number"},model:{value:(_vm.aliquota.aliquota),callback:function ($$v) {_vm.$set(_vm.aliquota, "aliquota", $$v)},expression:"aliquota.aliquota"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Predefinito","label-class":"font-weight-bolder"}},[_c('b-form-checkbox',{staticClass:"z-indexCheckbox",attrs:{"value":"1","unchecked-value":"0","name":"check-button","switch":"","inline":""},model:{value:(_vm.aliquota.predefinito),callback:function ($$v) {_vm.$set(_vm.aliquota, "predefinito", $$v)},expression:"aliquota.predefinito"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"codice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Codice","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"codice"},model:{value:(_vm.aliquota.codice),callback:function ($$v) {_vm.$set(_vm.aliquota, "codice", $$v)},expression:"aliquota.codice"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"% detraibile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Percentuale Detraibile","label-class":"font-weight-bolder"}},[_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"placeholder":"percentuale detraibile","type":"number"},model:{value:(_vm.aliquota.percentualeDetraibile),callback:function ($$v) {_vm.$set(_vm.aliquota, "percentualeDetraibile", $$v)},expression:"aliquota.percentualeDetraibile"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Natura","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"natura","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"natura"},model:{value:(_vm.aliquota.natura),callback:function ($$v) {_vm.$set(_vm.aliquota, "natura", $$v)},expression:"aliquota.natura"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Zona","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"zona","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"zona"},model:{value:(_vm.aliquota.zona),callback:function ($$v) {_vm.$set(_vm.aliquota, "zona", $$v)},expression:"aliquota.zona"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Note',"label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"note","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-textarea',{attrs:{"placeholder":"note","rows":"1"},model:{value:(_vm.aliquota.note),callback:function ($$v) {_vm.$set(_vm.aliquota, "note", $$v)},expression:"aliquota.note"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"descrizione","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrizione","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-textarea',{attrs:{"placeholder":"descrizione","rows":"1"},model:{value:(_vm.aliquota.descrizione),callback:function ($$v) {_vm.$set(_vm.aliquota, "descrizione", $$v)},expression:"aliquota.descrizione"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }