/* eslint-disable  */
import axios from 'axios'

export function getAliquoteIva() {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, {},
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-AliquotaIva',
                metodo: 'getAliquoteIva',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function setAliquotaIva(aliquota) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, aliquota,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-AliquotaIva',
                metodo: 'setAliquoteIva',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function deleteAliquote(aliquote) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, aliquote,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-AliquotaIva',
                metodo: 'deleteAliquoteIva',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}

