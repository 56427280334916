<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <b-modal
    id="modal-aliquotaiva-event-handler"
    ref="modal-aliquotaiva-event-handler"
    v-model="show"
    ok-only
    ok-title="Crea"
    centered
    size="md"
    :title="titleEventHandler"
    @ok.prevent="handleOk"
    @hidden="(() => resetAliquota())"
  >
    <!-- {{ aliquota }} -->
    <validation-observer ref="aliquotaivaobserverform">
      <b-row >
        <b-col
          cols="12"
          sm="8"
        >
          <validation-provider
            #default="{ errors }"
            name="aliquota"
            rules="required"
          >
            <b-form-group
              label="Aliquota"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="aliquota.aliquota"
                  placeholder="aliquota"
                  type="number"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Predefinito"
            label-class="font-weight-bolder"
          >
            <b-form-checkbox
              v-model="aliquota.predefinito"
              value="1"
              unchecked-value="0"
              class="z-indexCheckbox"
              name="check-button"
              switch
              inline
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="{ errors }"
            name="codice"
            rules="required"
          >
            <b-form-group
              label="Codice"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="aliquota.codice"
                  placeholder="codice"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="{ errors }"
            name="% detraibile"
            rules="required"
          >
            <b-form-group
              label="Percentuale Detraibile"
              label-class="font-weight-bolder"
            >
              <b-input-group append="%">
                <b-form-input
                  v-model="aliquota.percentualeDetraibile"
                  placeholder="percentuale detraibile"
                  type="number"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Natura"
            label-class="font-weight-bolder"
          >
            <validation-provider
              #default="{ errors }"
              name="natura"
              rules="required"
            >
            <b-input-group>
                <b-form-input
                  v-model="aliquota.natura"
                  placeholder="natura"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Zona"
            label-class="font-weight-bolder"
          >
            <validation-provider
              #default="{ errors }"
              name="zona"
              rules="required"
            >
            <b-input-group>
                <b-form-input
                  v-model="aliquota.zona"
                  placeholder="zona"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="'Note'"
            label-class="font-weight-bolder"
          >
            <validation-provider
              #default="{ errors }"
              name="note"
              rules="required"
            >
              <b-input-group>
                <b-form-textarea
                  v-model="aliquota.note"
                  placeholder="note"
                  rows="1"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="descrizione"
            rules="required"
          >
            <b-form-group
              label="Descrizione"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-textarea
                  v-model="aliquota.descrizione"
                  placeholder="descrizione"
                  rows="1"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BInputGroup, BFormInput, BRow, BCol, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import { setAliquotaIva } from '@/@core/api-service/dizionari/aliquotaIva'

export default {
  components: {
    BRow, BCol, BFormGroup, BInputGroup, BFormInput, ValidationProvider, ValidationObserver, BFormTextarea, BFormCheckbox,
  },
  data() {
    return {
      show: false,
      titleEventHandler: '',
      aliquota: {
        id: 0,
        codice: '',
        descrizione: '',
        natura: '',
        aliquota: '',
        percentualeDetraibile: '',
        note: '',
        zona: '',
        predefinito: 0,
      },
      required,
      locale: 'it',
    }
  },
  mounted() {
    localize(this.locale)
  },
  computed: {
  },
  methods: {
    handleOk() {
      this.$refs.aliquotaivaobserverform.validate().then(success => {
        if(success) {
          const prova = [{...this.aliquota}]
          // console.log(prova)
          setAliquotaIva(prova).then(res => {
            // console.log(res)
            if(res.data.esito === 'OK') {
              const aliquote = res.data.aliquoteIva.map(aliquota => ({ id: aliquota.id, codice: aliquota.codice, descrizione: aliquota.descrizione, natura: aliquota.natura, aliquota: aliquota.aliquota, percentualeDetraibile: aliquota.percentuale_detraibile, note: aliquota.note,  zona: aliquota.zona,  predefinito: aliquota.predefinito }))
              this.$emit('update-aliquotaiva', aliquote)
              this.resetAliquota()
              this.$nextTick(() => {
                this.$refs['modal-aliquotaiva-event-handler'].toggle('#toggle-btn')
              })
            }
          }).catch(e => e)
        }
       }).catch(e => e)

    },
    setAliquota(aliquota) {
      this.aliquota = { ...aliquota }
    },
    openModal() {
      this.show = true
    },
    setTitle(title) {
      this.titleEventHandler = title
    },
    resetAliquota() {
      this.aliquota = {
        id: 0,
        codice: '',
        descrizione: '',
        natura: '',
        aliquota: '',
        percentualeDetraibile: '',
        note: '',
        zona: '',
        predefinito: 0,
      }
    },
  },
}
</script>

<style>

</style>
